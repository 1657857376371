<template>
    <div>
        <MainMenu/>

        <div id="therapist-main" style="overflow:hidden;height: 100vh;">

            <v-row v-if="displayNearestSessionInfo" class="fill-height align-content-center">
                <v-col style="text-align: center">
                    <div v-if="user?.isATherapist()" class="header">Najbliższa sesja z pacjentem</div>
                    <div v-else class="header">Najbliższa sesja z terapeutą</div>
                    <div class="text">{{ nearestSession.date.format('dddd, D MMMM, HH:mm') }}</div>
                    <div style="text-align: center" v-if="nearestSession.url">
                        <br>
                        <a class="meeting-link" target="_blank" :href="nearestSession.url">
                            {{ user.isATherapist() ? 'Rozpocznij' : 'Dołącz' }}
                        </a>
                    </div>
                    <div style="text-align: center;" v-if="newChatEnabled">
                        <br>
                        <button class="meeting-link" @click="startSession">Rozpocznij sesję</button>
                    </div>
                    <div style="text-align: center" v-if="nearestSession">
                        <br>
                        <button class="cancel-link" @click="openCancelDialog">Odwołaj sesję</button>
                    </div>
                </v-col>
            </v-row>

            <v-row v-if="displayVideoChat" class="fill-height align-content-center video-bg">
                <video class="remote-stream"
                       autoplay
                       playsinline
                       v-if="remoteStream != null"
                       :srcObject.prop="remoteStream"></video>
                <video class="local-stream"
                       v-if="localStream != null"
                       autoplay
                       playsinline
                       muted
                       :class="{'peer-connected': remoteStream != null}"
                       :srcObject.prop="localStream"></video>
                <div class="controls">
                    <button @click="toggleMute" class="mute" :class="{'muted': muted}">
                        <font-awesome-layers fixed-width>
                            <font-awesome-icon icon="microphone"/>
                            <font-awesome-icon icon="slash"/>
                        </font-awesome-layers>
                    </button>
                    <button @click="toggleVideoStopped" class="stop-video" :class="{'stopped': videoStopped}">
                        <font-awesome-layers fixed-width>
                            <font-awesome-icon icon="camera"/>
                            <font-awesome-icon icon="slash"/>
                        </font-awesome-layers>
                    </button>
                    <button @click="hangUp" class="hang-up">
                        <font-awesome-icon icon="phone" fixed-width/>
                    </button>
                </div>
            </v-row>

        </div>

        <v-dialog :value="cancelling" max-width="400" @click:outside="hideCancelDialog">
            <ConfirmationDialog title="Odwołanie sesji"
                                @hide="hideCancelDialog"
                                :text="$t(cancelTextKey)"
                                confirm-text="Odwołaj sesję"
                                confirm-icon="times"
                                :action="cancelSession"/>
        </v-dialog>

    </div>
</template>

<script lang="ts">
import MainMenu from "./MainMenu.vue";
import ConfirmationDialog from "@/components/Common/ConfirmationDialog.vue";
import dayjs from "dayjs";
import Base from "@/mixins/Base";
import Component, {mixins} from "vue-class-component";
import {TherapySession} from "@/service/store/Module/domain";
import Button from "@/components/Form/Button.vue";
import {therapySessionStore, userStore} from "@/service/store";

@Component<TherapyPage>({
    name: "TherapyPage",
    components: {
        Button,
        ConfirmationDialog,
        MainMenu,
    }
})
export default class TherapyPage extends mixins(Base) {
    cancelling = false;

    beforeDestroy() {
        // todo should we allow user to browse page during video call, i.e., to read messages from chat?
        // this.hangUp();
    }

    get user() {
        return userStore.user;
    }

    get nearestSession() {
        const nearestSession: TherapySession | null = this.getter('getNearestSession');
        nearestSession && therapySessionStore.checkNewChat(nearestSession.chat_channel);
        return nearestSession;
    }

    get displayNearestSessionInfo(): boolean {
        return this.nearestSession != null && !this.videoChatStarted;
    }

    get displayVideoChat(): boolean {
        return this.videoChatStarted;
    }

    get videoChatStarted() {
        return therapySessionStore.videoChatStarted;
    }

    get muted() {
        return therapySessionStore.muted;
    }

    get videoStopped() {
        return therapySessionStore.videoStopped;
    }

    get localStream(): MediaStream | null {
        return therapySessionStore.localStream;
    }

    get remoteStream(): MediaStream | null {
        return therapySessionStore.remoteStream;
    }

    get newChatEnabled(): boolean {
        return therapySessionStore.newChatEnabled;
    }

    get cancelTextKey(): string {
        return this.freeOfCharge ? 'cancel_session_confirm_refund' : 'cancel_session_confirm_no_refund';
    }

    private get freeOfCharge(): boolean {
        const session = this.nearestSession;
        if (!session) {
            return false;
        }
        const now = dayjs();
        return now.isBefore(session.date) && now.add(24, 'hours').isBefore(session.date);
    }

    async startSession() {
        await therapySessionStore.invite({
            localUserId: this.user!.id,
            remoteUserId: this.nearestSession!.peer_id
        });
    }

    hangUp() {
        therapySessionStore.hangUp();
    }

    toggleMute() {
        therapySessionStore.toggleMute();
    }

    toggleVideoStopped() {
        therapySessionStore.toggleVideoStopped();
    }

    cancelSession() {
        this.action('cancelSession', this.nearestSession!);
        this.cancelling = false;
    }

    openCancelDialog() {
        this.cancelling = true;
    }

    hideCancelDialog() {
        this.cancelling = false;
    }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables.scss';

.header {
    text-align: center;
    font-size: 32px;
    font-weight: 500;
}

.text {
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    margin: 0 auto;
}

.meeting-link {
    background-color: #131313;
    color: #ffffff;
    padding: 10px 20px;
    font-weight: bold;
    font-size: 14px;
}

.start-link {
    color: #fff;
    padding: 8px 30px;
    background-color: #1a1a1a;
    font-size: 16px;
}

.cancel-link {
    color: #1a1a1a;
    padding: 5px 20px;
    background-color: #ddd;
    font-size: 14px;
}

.cancel-link:hover {
    background-color: #eee;
}

.cancel-link:active {
    background-color: #ccc;
}

a.meeting-link:hover, a.meeting-link:focus {
    color: #ccc;
}

a.meeting-link:active {
    background-color: #434343;
}

a.meeting-link:disabled {
    background-color: #ccc;
}

a.meeting-link:disabled:hover, a.meeting-link:disabled:focus {
    color: #ffffff;
}

svg {
    margin-right: 2px;
}

.video-bg {
    background-color: black;
    width: calc(100% + 20px);
    height: 100vh;
    position: relative;
}

.video-bg video {
    width: calc(100% + 20px);
    height: 100vh;
}

.local-stream.peer-connected {
    position: absolute;
    width: 150px;
    height: 100px;
    top: 20px;
    right: 20px;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
        top: 55px;
    }
}

.controls {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.controls .hang-up {
    background-color: red;
    color: white;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    opacity: 0.8;
    margin: 0 5px;
}

.controls .hang-up:hover {
    opacity: 1;
}
.controls .hang-up svg {
    transform: rotate(180deg);
}

.controls .mute {
    background-color: darkgray;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    opacity: 0.5;
    margin: 0 5px;
}

.controls .mute:hover {
    opacity: 0.6;
}
.controls .mute.muted {
    opacity: 1;
}

.controls .stop-video {
    background-color: darkgray;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    opacity: 0.5;
    margin: 0 5px;
}

.controls .stop-video:hover {
    opacity: 0.6;
}
.controls .stop-video.stopped {
    opacity: 1;
}
</style>
