<template>
    <div>
        <div class="mobile-header">
            <v-row no-gutters>
                <v-col cols="auto" style="line-height: 35px;">
                    <button :class="{'open':drawer}"
                            style="opacity:0.6;z-index:1000;outline:none;padding:3px;top:50px;left:15px;width:35px;height:35px;/*border:1px solid #8b8a8a;*/background-color:#fff;box-shadow:none;border-radius:0;"
                            @click.stop="drawer = !drawer">
                        <font-awesome-icon style="width: 22px; height: 22px;" icon="bars"/>
                    </button>
                </v-col>
                <v-col style="line-height: 35px; text-align: center">
                    <img :src="require('../../assets/logo.svg')" alt="LOGO"
                         style="width: 35px; height: 35px; vertical-align: -11px;">
                    {{ $t('app_name') }}
                </v-col>
                <v-col cols="auto" style="width: 35px;">
                </v-col>
            </v-row>
        </div>
        <v-navigation-drawer v-model="drawer" app mobile-breakpoint="800" :width="295">
            <v-row style="margin-left:0;margin-right:0;" v-if="user">

                <v-col cols="auto" class="pa-0 fill-height" style="width:35px;color:#fff;">
                    <div style="width:35px;height:35px;background-color:#fff;color:#000;">
                        <img :src="require('../../assets/logo.svg')" alt="LOGO" style="width: 35px; height: 35px;">
                    </div>
                    <span class="icon" :class="{'active': $router.currentRoute.name === 'route_calendar'}"
                          v-if="user.isATherapist()">
                        <font-awesome-icon icon="calendar-alt"></font-awesome-icon>
                    </span>
                    <span class="icon" :class="{'active': $router.currentRoute.name === 'route_consultation'}"
                          v-if="!user.therapist && !user.consultant">
                        <img :src="require('../../assets/icons/consultation.svg')" :alt="$t('consultation')">
                    </span>
                    <span class="icon" :class="{'active': $router.currentRoute.name === 'route_chat'}"
                          v-if="user.active_therapy">
                        <img :src="require('../../assets/icons/chat.svg')" :alt="$t('daily_messages')">
                    </span>
                    <span class="icon" v-if="nearestSession"
                          :class="{'active': $router.currentRoute.name === 'route_therapy'}">
                        <img :src="require('../../assets/icons/session.svg')" :alt="$t('therapist_session')">
                    </span>
                </v-col>
                <v-col class="pa-0">
                    <div style="width:100%;height:35px;padding:5px 5px 5px 16px;">
                        {{ $t('app_name') }}
                    </div>

                    <!-- menu for patient  -->
                    <div v-if="user.isAPatient()">
                        <router-link v-if="user.consultation" :to="{ name: 'route_consultation' }">
                            <div
                                :class="{ 'nav-tile': true, 'active': $router.currentRoute.name === 'route_consultation', 'unread': user.consultation.unread }"
                                @click="goToConsultation">
                                {{ $t('consultation') }}
                                <br>
                                <span class="last-message">{{ lastMessage(user.consultation) }}</span>
                                <font-awesome-icon v-if="user.consultation.unread" icon="circle"></font-awesome-icon>
                            </div>
                        </router-link>

                        <div v-if="!user.active_therapy"
                             style="width:100%;padding:16px;color:#1a1a1a;text-align:center;font-size:12px;font-weight:500;line-height: 15px;">
                            <img :src="require('../../assets/logo.png')" style="display: inline-block; margin: 20px 0;"
                                 alt="">
                            <br>
                            <span v-html="$t('first_consultation_main_menu_description')"></span>
                        </div>

                        <router-link v-if="user.active_therapy" :to="{ name: 'route_chat' }">
                            <div
                                :class="{ 'nav-tile': true, 'active': $router.currentRoute.name === 'route_chat', 'unread': user.active_therapy.unread }"
                                @click="goToChat">
                                {{ $t('daily_messages') }}
                                <br>
                                <span class="last-message">{{ lastMessage(user.active_therapy) }}</span>
                                <font-awesome-icon v-if="user.active_therapy.unread"
                                                   icon="circle"></font-awesome-icon>
                            </div>
                        </router-link>
                    </div>

                    <div v-else>
                        <router-link :to="{name: 'route_calendar'}">
                            <div @click="deactivateChat" v-if="user.therapist"
                                 :class="{'nav-tile': true, active: $router.currentRoute.name === 'route_calendar'}">
                                {{ $t('calendar') }}
                                <br>
                                <span>{{ $t('calendar_sessions') }}</span>
                            </div>
                        </router-link>
                    </div>

                    <router-link v-if="nearestSession" :to="{ name: 'route_therapy' }">
                        <div @click="deactivateChat"
                             :class="{ 'nav-tile': true, 'active': $router.currentRoute.name === 'route_therapy' }">
                            {{ user.isATherapist() ? $t('therapist_nearest_session') : $t('patient_nearest_session') }}
                            <br>
                            <span>{{ nearestSession.date.format('dddd, D MMMM, HH:mm') }}</span>
                        </div>
                    </router-link>

                    <ul v-if="!user.isAPatient()" class="therapies-list">
                        <li><h3>{{ $t('messages') }}</h3></li>
                    </ul>

                    <ul v-if="!user.isAPatient()" class="therapies-list">
                        <li v-if="this.unreadChats.length"><h4>{{ $t('messages_unread') }}</h4></li>
                        <li v-for="(therapy, key) in this.unreadChats" :key="key"
                            @click="activateChat(therapy.chat_channel)" class="nav-tile"
                            :class="{ 'cursor': true, 'unread': therapy.unread, 'active': $router.currentRoute.name === 'route_chat'
                                && uriToId(therapy.chat_channel) === $store.getters.getCurrentChatChannelId }">
                            {{ therapy.user.firstname }} {{ therapy.user.lastname }}
                            <br>
                            <span class="last-message">{{ lastMessage(therapy) }}</span>
                            <font-awesome-icon v-if="therapy.unread" icon="circle"></font-awesome-icon>
                        </li>
                    </ul>

                    <ul v-if="!user.isAPatient()" class="therapies-list">
                        <li v-if="this.readChats.length"><h4>{{ $t('messages_read') }}</h4></li>
                        <li v-for="(therapy, key) in this.readChats" :key="key"
                            @click="activateChat(therapy.chat_channel)" class="nav-tile"
                            :class="{ 'cursor': true, 'unread': therapy.unread, 'active': $router.currentRoute.name === 'route_chat'
                                && uriToId(therapy.chat_channel) === $store.getters.getCurrentChatChannelId }">
                            {{ therapy.user.firstname }} {{ therapy.user.lastname }}
                            <br>
                            <span class="last-message">{{ lastMessage(therapy) }}</span>
                            <font-awesome-icon v-if="therapy.unread" icon="circle"></font-awesome-icon>
                        </li>
                    </ul>
                </v-col>
            </v-row>

            <template v-slot:append v-if="user">
                <v-row id="drawer-footer" style="height:100%;margin-left:0;margin-right:0;">
                    <v-col cols="auto" class="pa-0" style="width:35px;height:100%;color:#fff;text-align: center;">
                        <img alt="Account" :src="require('../../assets/icons/account.svg')" style="margin-top: 14px;">
                    </v-col>
                    <v-col class="pa-0">
                        <ul id="bottom-menu">
                            <li @click="deactivateChat"
                                :class="{'active': $router.currentRoute.name === 'route_settings'}">
                                <router-link :to="{ name: 'route_settings' }">
                                    {{ $t('my_account') }}
                                </router-link>
                            </li>
                            <li @click="deactivateChat" v-if="user.isAPatient()"
                                :class="{'active': $router.currentRoute.name === 'route_subscription'}">
                                <router-link :to="{ name: 'route_subscription' }">
                                    {{ $t('plans') }}
                                    <span v-if="activeSubscription">
                                        <br>{{ activeSubscription.plan.name }}
                                        <span class="active-plan">{{ $t('plans_active') }}</span>
                                    </span>
                                </router-link>
                            </li>
                            <li>
                                <a @click="logout">{{ $t('sign_out') }}</a>
                            </li>
                            <li style="padding: 2px 14px; font-size: 10px; opacity: 0.3;">
                                &copy; 2021 {{ $t('app_name') }} ({{ $t('app_version') }})
                            </li>
                        </ul>
                    </v-col>
                </v-row>
            </template>
        </v-navigation-drawer>
    </div>
</template>

<script lang="ts">
import Component, {mixins} from "vue-class-component";
import {Chat} from "@/dataModel/chat";
import {User} from "@/dataModel/user";
import Base from "@/mixins/Base";
import {Session} from "@/dataModel/therapy";
import {Subscription} from "@/dataModel/subscription";
import {therapistChatStore, userStore} from "@/service/store";

@Component<MainMenu>({})
export default class MainMenu extends mixins(Base) {
    drawer = null;

    get lastMessage() {
        return (chat: Chat) => {
            const author = chat.last_message_author === this.user?.id ? this.$t('me') : chat.last_message_author_name;
            return `${author}: ${chat.last_message}`;
        };
    }

    get unreadChats(): Chat[] {
        return therapistChatStore.unreadChats;
    }

    get readChats(): Chat[] {
        return therapistChatStore.readChats;
    }

    get user(): User | null {
        return userStore.user;
    }

    get activeSubscription(): Subscription | null {
        if (this.user) {
            return this.user.active_subscription;
        }
        return null;
    }

    get nearestSession(): Session | null {
        return this.getter('getNearestSession');
    }

    logout() {
        this.$store.dispatch('doLogout');
    }

    deactivateChat() {
        this.$store.commit('resetActiveChat');
    }

    activateChat(channelId: string) {
        channelId = this.uriToId(channelId);
        if (this.$router.currentRoute.name !== 'route_chat') {
            this.$router.push({name: 'route_chat'})
                .then(() => this.$store.dispatch('loadChatChannel', channelId));
        } else {
            this.$store.dispatch('loadChatChannel', channelId);
        }
    }

    goToChat() {
        this.$store.dispatch('loadChatChannel', this.user!.active_therapy!.chat_channel.slice(-36));
    }

    goToConsultation() {
        this.$store.dispatch('loadChatChannel', this.user!.consultation!.chat_channel.slice(-36));
    }

    uriToId(uri: string) {
        if (0 === uri.indexOf('/')) {
            const part = uri.split('/');
            uri = part[part.length - 1];
        }

        return uri;
    }
}
</script>

<style scoped lang="scss">

.v-navigation-drawer {
    background-color: #f2f2f2;
    border-right: 1px solid #e0e0e0;
    background-size: 35px 100%;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAAAMSURBVBhXY5CSkgIAAKAAT4ZJPBQAAAAASUVORK5CYII=');
}

@media only screen and (min-width: 800px) {
    .mobile-header {
        display: none;
    }
}

.mobile-header {
    z-index: 1000;
    background-color: #fff;
    width: 100%;
    position: fixed;
    height: 35px;
    box-shadow: #ccc 0 0 5px;
    padding: 0;
    line-height: 35px;

    button svg {
        transition: all 250ms ease-in-out;
    }

    button.open svg {
        transform: rotate(-90deg);
    }
}

ul#bottom-menu {
    list-style: none;
    padding-left: 0;

    li {
        padding: 0;
        font-size: 12px;
        opacity: 0.4;
        font-weight: 500;
        border-top: 1px solid #dbdbdb;

        a {
            padding: 10px 15px;
            display: block;
            color: #1a1a1a;
        }
    }
}

.icon {
    display: block;
    width: 35px;
    height: 76px;
    line-height: 76px;
    text-align: center;

    &.active {
        background-color: #404040;
    }
}

.nav-tile {
    width: 100%;
    height: 76px;
    padding: 16px;
    color: #1a1a1a;
    font-size: 16px;
    position: relative;
}

.nav-tile span {
    display: block;
    opacity: 0.4;
    font-size: 12px;
    line-height: 18px;
    height: 18px;
    //font-weight: 500;
    overflow-y: hidden;
    padding-right: 20px;
}

.nav-tile.unread {
    font-weight: 500;
}

.nav-tile.active {
    background-color: #faf9f9;
}

.nav-tile.inactive {
    opacity: 0.4;
}

.active-plan {
    border: 1px solid #1a1a1a;
    display: inline-block;
    padding: 1px 3px;
    margin-left: 5px;
    border-radius: 1px;
}

ul.therapies-list, ul#therapist-menu {
    padding-left: 0;
}

ul#therapist-menu h3, ul.therapies-list h3 {
    padding: 10px 0 0;
    margin: 0;
    font-size: 14px;
    opacity: 0.6;
    font-weight: 600;
}

ul.therapies-list h4 {
    padding: 10px 0 0;
    margin: 0;
    font-size: 13px;
    opacity: 0.4;
    font-weight: 600;
}

ul#therapist-menu a {
    color: rgba(0, 0, 0, 0.87);
    display: block;
}

ul.therapies-list li, ul#therapist-menu li {
    border-top: 1px solid #ddd;
    list-style: none;
    padding: 5px 15px;
}

ul.therapies-list li.active, ul#therapist-menu li.active {
    background-color: #ccc;
}

ul.therapies-list .nav-tile {
    height: auto;
    position: relative;
}

ul.therapies-list .nav-tile span {
    font-weight: 400;
}

ul.therapies-list .nav-tile.unread {
    font-weight: 500;
}

.nav-tile.unread .fa-circle {
    position: absolute;
    right: 15px;
    bottom: 10px;
    font-size: 8px;
    color: #1a1a1a;
}

.nav-tile.unread span {
    font-weight: 500;
    opacity: 1;
}

ul#bottom-menu li.active a {
    background-color: #ccc;
}

.last-message {
    overflow-x: hidden;
    max-width: 220px;
    white-space: nowrap;
}
</style>
