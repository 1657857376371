export default {
    planWithChatOnly: 139, /* plan 1 */
    planWith1SessionAndChat: 149, /* plan 2 */
    planWith4SessionsAndChat: 189, /* plan 3 */
    planWith4Sessions: 139, /* plan 4 */
    session30: 139,
    session50: 189,
    trialWithoutSession: 109,
    trialWithSession: 149
}
