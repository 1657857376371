<template>
    <div>
        <MainMenu/>
        <div id="chat-main" style="overflow:hidden;" :style="{'height': `${windowHeight}px`}">
            <ChatTherapistHeader/>
            <ChatWindow/>
            <v-dialog :value="calendarOpen" max-width="300" @click:outside="toggleCalendar">
                <CreateSessionDialog @date-selected="createSession" />
            </v-dialog>
            <v-col id="panel-bottom" class="pt-0 pb-0" style="position:absolute;bottom:0;width:100%;min-height:67px;margin: 0;background-color: rgb(250, 249, 249);border-top:1px solid #1a1a1a;">
                <v-row align="end">
                    <v-col cols="auto" style="padding-right: 0;">
                        <span class="chat-button" v-if="this.getRecorderState()" @click="closeRecorder()">
                            <img :src="require('../../assets/icons/record.svg')" alt="Zamknij">
                        </span>
                        <span class="chat-button" v-else @click="initRecorder()">
                            <img :src="require('../../assets/icons/record.svg')" alt="Nagraj">
                        </span>
                        <label class="chat-button" for="image">
                            <img :src="require('../../assets/icons/send-media.svg')" alt="Dodaj plik">
                            <input id="image" type="file" accept="image/png,image/jpeg,video/mp4,application/x-mpegURL,video/webm" ref="files"
                                   multiple v-on:change="handleFileUploads" style="display:none;"/>
                        </label>
                        <span v-if="this.getUser() && this.getUser().isATherapist()" class="chat-button fa-container" @click="toggleCalendar">
                            <font-awesome-icon icon="calendar-plus"></font-awesome-icon>
                        </span>
                    </v-col>

                    <v-col cols="auto" v-if="resources.length" style="padding-left: 0;">
                        <ul class="resources">
                            <li v-for="(resource, key) in resources" :key="key">
                                <SecureImage v-if="resource.mime_type === 'image/jpeg'" :mime-type="resource.mime_type" :storage-id="resource.id" :control-remove="true" :limit-size="true"/>
                                <SecureImage v-else-if="resource.mime_type === 'image/png'" :mime-type="resource.mime_type" :storage-id="resource.id" :control-remove="true" :limit-size="true"/>
                                <AudioPlayer v-else-if="resource.mime_type === 'audio/mpeg'" :mime-type="resource.mime_type" thumb :storage-id="resource.id" :control-remove="true" :limit-size="true"/>
                                <AudioPlayer v-else-if="resource.mime_type === 'audio/ogg'" :mime-type="resource.mime_type" thumb :storage-id="resource.id" :control-remove="true" :limit-size="true"/>
                                <AudioPlayer v-else-if="resource.mime_type === 'video/webm'" :mime-type="resource.mime_type" thumb :storage-id="resource.id" :control-remove="true" :limit-size="true"/>
                            </li>
                        </ul>
                    </v-col>

                    <v-col :style="{display: windowWidth > 800 ? 'block' : 'none'}" style="padding-left: 0; padding-right: 0;">
                        <span class="chars-left" v-if="charsLeft < 20">{{ Math.max(charsLeft, 0) }} / {{ maxLength }}</span>
                        <textarea title="Napisz wiadomość..." placeholder="Napisz wiadomość..." v-model="message" :maxlength="maxLength"
                                  @keydown.enter="sendMessageInTextarea" @input="onTextChanged" @change="onTextChanged" rows="1" ref="messageDesktop"
                                  style="width:100%;outline:none;padding:10px;resize: none;line-height:20px;height:40px;font-size:14px;overflow-y: hidden;
                                  box-shadow: inset rgb(221, 221, 221) 0 0 10px 0;border:1px solid rgb(219, 219, 219);background-color: #fff; transition: width 250ms ease-in-out;"></textarea>
                    </v-col>
                    <v-spacer :style="{display: windowWidth > 800 ? 'none' : 'block'}"></v-spacer>
                    <v-col cols="auto">
                        <Button @click.native="sendMessage" :icon="`paper-plane`" style="margin-bottom: 2px" :placeholder="$t('send')"/>
                    </v-col>

                </v-row>
                <v-row :style="{display: windowWidth <= 800 && !this.getRecorderState() ? 'block' : 'none'}">
                    <v-col class="pa-0" v-if="!this.getRecorderState()" style="border-top:1px solid #000;background-color:#faf9f9;">
                        <span class="chars-left-mobile" v-if="charsLeft < 20">{{ Math.max(charsLeft, 0) }} / {{ maxLength }}</span>
                        <textarea title="Napisz wiadomość..." placeholder="Napisz wiadomość..." v-model="message" ref="messageMobile"
                                  @keypress.enter="sendMessageInTextarea" @input="onTextChanged" @change="onTextChanged" rows="1"
                                  style="width:100%;line-height:20px;outline:none;resize: none;font-size:14px;padding: 10px;" :maxlength="maxLength"></textarea>
                    </v-col>
                </v-row>
                <v-row>
                    <ChatAudioRecorder :full-width="windowWidth <= 800"/>
                </v-row>
            </v-col>

        </div>
    </div>
</template>

<script lang="ts">
    import StorageRepository from "../../repository/Resource/StorageRepository";
    import Button from '@/components/Form/Button.vue';
    import ChatWindow from "../Chat/ChatWindow.vue";
    import ChatTherapistHeader from "../Chat/ChatTherapistHeader.vue";
    import SecureImage from "../Common/SecureImage.vue";
    import AudioPlayer from "../Common/AudioPlayer.vue";
    import UriMixin from "../../mixins/UriMixin";
    import MainMenu from "./MainMenu.vue";
    import DateTimeMixin from "@/mixins/DateTimeMixin";
    import ChatAudioRecorder from "@/components/Recorder/ChatAudioRecorder.vue";
    import {mapActions, mapGetters} from "vuex";
    import ImageReduce from "@/service/ImageReduce";
    import CreateSessionDialog from "@/components/Common/CreateSessionDialog.vue";
    import Component, {mixins} from "vue-class-component";
    import Base from "@/mixins/Base";
    import {PendingMessage, Resource} from "@/dataModel/chat";

    const reducer = new ImageReduce();

    let MAX_LENGTH = 2000;

    @Component<ChatPage>({
        name: "ChatPage",
        mixins: [
            UriMixin,
            DateTimeMixin,
        ],
        components: {
            CreateSessionDialog,
            ChatAudioRecorder,
            MainMenu,
            AudioPlayer,
            SecureImage,
            ChatTherapistHeader,
            ChatWindow,
            Button,
        },
        mounted() {
            if (this.$store.getters.getUser && (this.$store.getters.getUser.therapist || this.$store.getters.getUser.consultant)) {
                MAX_LENGTH = 1000000;
            }
        },
        computed: {
            maxLength: {
                get() {
                    return MAX_LENGTH;
                }
            },
            charsLeft: {
                get() {
                    return MAX_LENGTH - this.message.length;
                }
            }
        },
        methods: {
            ...mapGetters([
                'getSocket',
                'getRecorderState',
                'getRecorderState',
                'getUser',
            ]),
            ...mapActions([
                'initRecorder',
                'closeRecorder',
            ]),
            play() {
                // @ts-ignore
                this.aud.play();
            },
            pause() {
                // @ts-ignore
                this.aud.pause();
            },
        }
    })
    export default class ChatPage extends mixins(Base) {

        calendarOpen = false;

        get message(): string {
            return (this.getter('message/pending') as PendingMessage).message;
        }

        set message(newMessage: string) {
            this.action('message/setMessage', newMessage);
        }

        get resources(): Resource[] {
            return (this.getter('message/pending') as PendingMessage).resources;
        }

        get windowHeight(): number {
            return this.getter('getWindowHeight');
        }

        get windowWidth(): number {
            return this.getter('getWindowWidth');
        }

        get textAreas(): HTMLTextAreaElement[] {
            return [
                this.$refs.messageMobile as HTMLTextAreaElement | null,
                this.$refs.messageDesktop as HTMLTextAreaElement | null
            ].filter((element) => element != null) as HTMLTextAreaElement[];
        }

        get uploadedFiles(): FileList {
            return (this.$refs.files as HTMLInputElement).files ?? new FileList();
        }

        addResource(resource: Resource) {
            this.action('message/addResource', resource);
        }

        updateBottomPanelHeight() {
            this.setter('setBottomPanelHeight', (document.getElementById('panel-bottom')!.scrollHeight - (this.windowWidth < 800 ? 20 : 0)));
        }

        toggleCalendar() {
            this.calendarOpen = !this.calendarOpen;
        }

        autoGrow() {
            const element = (this.windowWidth < 800 ? this.$refs.messageMobile : this.$refs.messageDesktop) as HTMLTextAreaElement;
            if (!element) return;
            element.style.height = 'auto';
            let newHeight = element.scrollHeight;
            if (newHeight < 60) newHeight = 40;
            if (newHeight > 200) {
                newHeight = 200;
                element.style.overflowY = 'scroll';
            } else {
                element.style.overflowY = 'hidden';
            }
            this.textAreas.forEach((area) => area.style.height = newHeight + 'px');
            this.updateBottomPanelHeight();
        }

        onTextChanged() {
            this.autoGrow();
        }

        sendMessage() {
            if ((this.message.trim().length || this.resources.length) && this.message.length <= MAX_LENGTH) {
                this.$store.dispatch('message/send');
                this.updateBottomPanelHeight();
            }
        }

        sendMessageInTextarea(event: KeyboardEvent) {
            if (event.ctrlKey) {
                event.stopPropagation();
                if ((this.message.trim().length || this.resources.length) && this.message.length <= MAX_LENGTH) {
                    this.$store.dispatch('message/send');
                    this.textAreas.forEach((area) => area.style.height = '40px');
                    this.updateBottomPanelHeight();
                }
                event.preventDefault();
            }
        }

        createSession({date, sessionType}: {date: string, sessionType: string}) {
            this.toggleCalendar();
            this.message = `/termin ${date} 00 ${sessionType}`;
            this.sendMessage();
        }

        handleFileUploads() {
            const uploadedFiles = this.uploadedFiles;
            if (uploadedFiles.length > 5) {
                alert('Maximum allowed files for upload is 5.');
                return false;
            }
            for (let i = 0; i < uploadedFiles.length; i++) {
                reducer.toBlob(uploadedFiles.item(i), {max: 1000})
                    .then((file: Blob) => StorageRepository.create(file, 'media', 'Some title'))
                    .then((resource: Resource) => this.addResource(resource));
            }
        }
    }
</script>

<style scoped>
ul.resources {
    list-style: none;
    padding-left: 0;
}

.chat-button {
    text-align: center;
    height: 30px;
    width: 30px;
    line-height: 30px;
    display: inline-block;
    border: 1px solid #8b8a8a;
    cursor: pointer;
    margin-right: 10px;
}
.chars-left {
    display: block;
    text-align: right;
    font-size: 10px;
    opacity: 0.6;
    margin-bottom: 5px;
}
.chars-left-mobile {
    display: block;
    text-align: right;
    font-size: 10px;
    opacity: 1;
    padding: 5px;
    background-color: #fcf3f3;
    position: absolute;
    right: 0;
}
.chat-button.fa-container {
    vertical-align: 2px;
}
.chat-button.fa-container svg {
    width: 30px;
    height: 30px;
    padding: 7px;
}
</style>
